import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Layout from '../components/Layout'
import Footer from '../components/footer'
import facebooklogo from '../assets/images/icon/facebook.svg'
import linkedinlogo from '../assets/images/icon/linkedin.svg'
import knsicongrey from '../assets/images/icon/kns-icon-grey.svg'
import knsblue from '../assets/images/logo/kns-blue.svg'
import $ from 'jquery'
import LocalizedLink from '../LocalizedLink'

class IYSPage extends React.Component {
  componentDidMount() {
    if (window.location.pathname === '/iys') {
      $('.logo a img').attr('src', knsblue)
      $('.menu ul li a').css('color', '#1b437b')
      $('.header').css('background', '#ffffff')
      $('.language').css('border-color', '#1b437b')
      $('.language a span').css('color', '#1b437b')
      $('.other-page-social').show(100)
      $('#nav-toggle span').addClass('colorline')
      $('.numbers h1,.numbers h4').css('color', '#1b437b')
    }

    $('.mb-nav-btn').click(function () {
      $('.mobile-menu').fadeIn(200)
    })
    $('.close').click(function () {
      $('.mobile-menu').fadeOut(200)
    })

    $('.mobile-menu ul li a').click(function () {
      $('.mobile-menu').fadeOut(200)
    })
  }
  render() {
    return (
      <Layout locale={this.props.pageContext.locale}>
        <body className="other-page">
          <header className="header">
            <div className="row">
              <div className="col-3 logo">
                <a href="/">
                  <img
                    src={require('../assets/images/logo/kns-blue.svg')}
                    alt="KNS"
                    title="KNS"
                  />
                </a>
              </div>
              <div className="col-9">
                {/*<div href="#" className="language">*/}

                {/*{this.props.pageContext.locale === "en" ? this.state.en : this.state.tr}*/}

                {/*</div>*/}
                <a href="#" id="nav-toggle" class="mb-nav-btn">
                  <span />
                </a>
                <nav className="menu">
                  <ul>
                    <li>
                      <LocalizedLink to="/aboutPage">
                        <FormattedMessage id="about" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/platformsPage">
                        <FormattedMessage id="platforms" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/iys">
                        <FormattedMessage id="İYS" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/supportPage">
                        <FormattedMessage id="supports" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/clientsPage">
                        <FormattedMessage id="clients" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/platforms/venuex">
                        <FormattedMessage id="Venuex" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/careerPage">
                        <FormattedMessage id="Career" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/contactPage">
                        <FormattedMessage id="Contact" />
                      </LocalizedLink>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </header>

          <div className="mobile-menu">
            <div className="close">
              <img src={require('../assets/images/icon/close-nav.svg')} />
            </div>

            <nav className="mb-nav">
              <ul>
                <li>
                  <LocalizedLink to="/aboutPage">
                    <FormattedMessage id="about" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/platformsPage">
                    <FormattedMessage id="platforms" />
                  </LocalizedLink>
                </li>
                <li>
                      <LocalizedLink to="/iys">
                        <FormattedMessage id="İYS" />
                      </LocalizedLink>
                    </li>
                <li>
                  <LocalizedLink to="/supportPage">
                    <FormattedMessage id="supports" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/clientsPage">
                    <FormattedMessage id="clients" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/platforms/venuex">
                    <FormattedMessage id="Venuex" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/careerPage">
                    <FormattedMessage id="Career" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/contactPage">
                    <FormattedMessage id="Contact" />
                  </LocalizedLink>
                </li>
              </ul>
            </nav>
          </div>

          <article className="home-social">
            <h2>
              <FormattedMessage id="follow" />
            </h2>
            <figure className="blue-line" />
            <a
              href="https://www.facebook.com/knsbilisim/"
              className="fb"
              target="_blank"
            >
              <img src={facebooklogo} />
            </a>
            <a
              href="https://www.linkedin.com/company/kns-information-technologies/"
              className="linkedin"
              target="_blank"
            >
              <img src={linkedinlogo} />
            </a>
          </article>
          <section className="page">
            <div className="container">
              <div className="col-12">
                <figure className="kns-grey-icon">
                  <img src={knsicongrey} />
                </figure>

                <div className="page-prp">
                  <h1>İleti Yönetim Sistemi</h1>
                  {this.props.pageContext.locale == 'tr' && (
                    <>
                      <p>
                        Create a remarkable visitor experience with the touch of
                        a sustainable digital marketing platform.
                      </p>
                    </>
                  )}
                  {this.props.pageContext.locale == 'en' && (
                    <>
                      <p>
                        
                      </p>
                    </>
                  )}
                </div>

                <section className="about iys-content">
                  <div className="container">
                    <figure className="iys-image">
                      <img src={require('../assets/images/iys-image.svg')} />
                    </figure>
                    {this.props.pageContext.locale == 'tr' && (
                      <>
                        <p>
                          Since 2011, presenting more than 750+ products, KNS
                          has been building a bridge between physical and
                          digital world in 7 Countries, more than 150+ Shopping
                          Malls, Universities and City Hospitals.
                        </p>
                        <p>
                          For a sustainable customer relations experience, KNS
                          is implementing a one-stop management model on a
                          synchronized platform for its services such as Mobile
                          CRM and Loyalty integrations, web sites, native
                          applications and touchscreens.
                        </p>
                        <p>
                          Reaching over 250.000 end users through mobile
                          applications, KNS continues being the strongest
                          solution partner for shopping malls’ and their success
                          stories. Millions of mall visitors’ shopping behaviors
                          are being re-shaped via more than 200 touchscreens in
                          Turkey and across the globe.
                        </p>
                        <p>
                          Meeting all the digital marketing needs of shopping
                          malls, KNS is presenting basic, practical and customer
                          oriented solutions.
                        </p>
                      </>
                    )}

                    {this.props.pageContext.locale == 'en' && (
                      <>
                        <h3>KNS, IYS İş Ortağı Oldu</h3>
                        <p>
                          Alışveriş merkezlerinin tüm dijital pazarlama
                          ihtiyaçlarını karşılayan, dijital dönüşümlerine destek
                          olan KNS olarak, İleti Yönetim Sistemi (İYS) iş ortağı
                          olduğumuzu duyurmaktan mutluluk duyuyoruz.
                        </p>

                        <p>
                          Kayıtlı bir iş ortağı olarak tüm müşterilerimize
                          İYS’ye hızlı ve güvenilir entegre olma imkanı
                          sağlıyoruz. Bu entegrasyon ile tüm datanızı Ticaret
                          Bakanlığı tarafından 23/10/2014 tarihinde, 6563 sayılı
                          Elektronik Ticaretin Düzenlenmesi Hakkında Kanun ile
                          zorunlu kılınan kayıt koşullarını sağlayarak İYS’ye
                          taşıyoruz.
                        </p>

                        <h3>İleti Yönetim Sistemi nedir?</h3>

                        <p>
                          İleti Yönetim Sistemi, Hizmet
                          Sağlayıcıların arama, mesaj ve e-posta yoluyla ticari
                          elektronik ileti göndermek için alıcılardan aldıkları
                          onayları saklayıp yönetebilmesine, alıcıların
                          ise onay ve ret hakkını kullanabilmesine, ticari
                          elektronik ileti şikâyetlerini iletebilmesine ve
                          yönetilebilmesine imkân tanıyan, güvenlik
                          standartlarına sahip ulusal güvenli veri tabanı
                          sistemidir.
                        </p>

                        <p className="iys-i">
                          İletişim adresi sayısı 250.000’den az olan Hizmet
                          Sağlayıcılar, İLETİ Hizmetleri’ne ancak
                          yetkilendirdikleri iş ortakları üzerinden erişim
                          sağlayabilirler.
                        </p>

                        <p>
                          Yapılan son düzenlemeyle birlikte kayıtlı iletişim
                          adresi sayısı 250.000’den az olan Hizmet
                          Sağlayıcıların IYS’ye yalnızca iş ortakları
                          aracılığıyla entegre olabilecekleri belirtilmiştir.
                        </p>

                        <h3>KNS-İYS ne işe yarar?</h3>

                        <ul>
                          <li>
                            Kanuni yaptırımlarla zorunlu kılınmış İleti Yönetim
                            Sistemi’ne entegrasyonunuzu hızlı ve güvenilir
                            şekilde sağlar, bireysel başvuru süreçlerindeki
                            zaman kaybının önüne geçer.
                          </li>
                          <li>
                            Farklı platformlarda tutulan datanızı tek bir
                            platformda toplar,verilerinizi tekilleştirerek veri
                            kaybını sıfıra indirir.
                          </li>

                          <li>
                            Tekilleştirilen verilerinizi online ve güvenilir bir
                            platformda sürekli güncel tutmanızı sağlar.
                          </li>
                          <li>
                            Data girişi ve imha süreçlerini manuel ya da toplu
                            halde yönetmenizi sağlar.
                          </li>
                          <li>
                            İzinli tüm datanız ulusal veri tabanlarında
                            saklanır, veri güvenliği sağlanmış olur.
                          </li>

                          <li>
                            Müşterilerin onay ve ret bildirimleri, tüm izinli
                            hareketleri raporlanabilir halde tutulur.
                          </li>
                        </ul>

                        <h3>Sık Sorulan Sorular</h3>

                        <div className="bd-example">
                          <div className="accordion" id="accordionExample">
                            <div className="card">
                              <div className="card-header" id="headingOne">
                                <h2 className="mb-0">
                                  <button
                                    className="btn btn-link collapsed"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#collapseOne"
                                    aria-expanded="false"
                                    aria-controls="collapseOne"
                                  >
                                    İleti Yönetim Sistemi nedir?
                                  </button>
                                </h2>
                              </div>

                              <div
                                id="collapseOne"
                                className="collapse"
                                aria-labelledby="headingOne"
                                data-parent="#accordionExample"
                              >
                                <div className="card-body">
                                  <p>
                                    Resmî Gazete'de yayımlanarak yürürlüğe
                                    alınan Ticari İletişim ve Ticari Elektronik
                                    İletiler Hakkında Yönetmelik'e uygun olarak;
                                    Hizmet Sağlayıcılar'ın arama, mesaj ve
                                    e-posta gibi farklı tipte ileti izinlerini
                                    saklayıp yönetebilecekleri, alıcıların
                                    verdikleri izinleri görüntüleyip
                                    kaldırabilecekleri, izinsiz gönderimleri
                                    şikâyet edebilecekleri, kamunun ise ileti
                                    şikâyetlerini ve şikâyete konu iznin
                                    durumunu görüntüleyebilecekleri, web sitesi,
                                    kısa mesaj numarası ve çağrı merkezi
                                    üzerinden hizmet verecek, tüm izinleri zaman
                                    damgasıyla kayıt altına alıp güvenli biçimde
                                    saklayacak ulusal veri tabanı sistemidir.
                                  </p>
                                  <p>
                                    Detaylı bilgi için <a href="https://iys.org.tr/" target="_blank">tıklayınız</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="card">
                              <div className="card-header" id="headingTwo">
                                <h2 className="mb-0">
                                  <button
                                    className="btn btn-link collapsed"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo"
                                  >
                                    Ticari elektronik ileti nedir?
                                  </button>
                                </h2>
                              </div>
                              <div
                                id="collapseTwo"
                                className="collapse"
                                aria-labelledby="headingTwo"
                                data-parent="#accordionExample"
                              >
                                <div className="card-body">
                                  <p>
                                    Gerçek ve tüzel kişilerin ticari
                                    faaliyetleri doğrultusunda mal ve
                                    hizmetlerini tanıtmak, pazarlamak ya da
                                    işletmesinin tanınırlığını arttırmak
                                    amacıyla elektronik iletişim araçlarıyla
                                    yapılan her türlü ticari iletişimi ticari
                                    elektronik ileti olarak adlandırılır.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="card">
                              <div className="card-header" id="headingThree">
                                <h2 className="mb-0">
                                  <button
                                    className="btn btn-link collapsed"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree"
                                  >
                                    Hizmet Sağlayıcı kimdir?
                                  </button>
                                </h2>
                              </div>
                              <div
                                id="collapseThree"
                                className="collapse"
                                aria-labelledby="headingThree"
                                data-parent="#accordionExample"
                              >
                                <div className="card-body">
                                  <p>Kanun çerçevesinde, arama, mesaj, e-posta vb.
                                  araçları kullanarak ticari iletişim sağlayan
                                  gerçek ve tüzel kişiler Hizmet Sağlayıcı
                                  olarak adlandırılmaktadır.</p>
                                </div>
                              </div>
                            </div>

                            <div className="card">
                              <div className="card-header" id="headingFour">
                                <h2 className="mb-0">
                                  <button
                                    className="btn btn-link collapsed"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#collapseFour"
                                    aria-expanded="false"
                                    aria-controls="collapseFour"
                                  >
                                    İş ortağı kimdir?
                                  </button>
                                </h2>
                              </div>
                              <div
                                id="collapseFour"
                                className="collapse"
                                aria-labelledby="headingFour"
                                data-parent="#accordionExample"
                              >
                                <div className="card-body">
                                  <p>
                                    İş ortağı, İYS API lisansı kullanım hakkı
                                    elde ederek kendi müşterilerine İYS
                                    entegrasyon hizmeti sunan kişidir.
                                  </p>
                                  <p>
                                    CRM yazılım hizmeti, toplu SMS gönderimi,
                                    toplu e-posta gönderimi, çağrı merkezi
                                    hizmeti ve benzeri hizmetler veren firmalar
                                    iş ortağı olabilir.
                                  </p>

                                  <p>
                                    Firmaların iş ortağı olabilmek için şu
                                    şartları sağlamaları gerekir:
                                  </p>

                                  <ul>
                                    <li>
                                      ISO 22301 ve TÜRKAK onaylı ISO 27001
                                      kalite belgelerine sahip olunması veya bu
                                      belgelerin on iki ay içinde
                                      tamamlanacağının taahhüt edilmesi
                                    </li>
                                    <li>
                                      İYS İş Ortaklığı Sözleşmesi’nin
                                      imzalanması
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>

                            <div className="card">
                              <div className="card-header" id="headingFive">
                                <h2 className="mb-0">
                                  <button
                                    className="btn btn-link collapsed"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#collapseFive"
                                    aria-expanded="false"
                                    aria-controls="collapseFive"
                                  >
                                    İleti Yönetim Sistemi'ne kayıt olmadan
                                    ticari elektronik ileti göndermeye devam
                                    edebilir miyim?
                                  </button>
                                </h2>
                              </div>
                              <div
                                id="collapseFive"
                                className="collapse"
                                aria-labelledby="headingFive"
                                data-parent="#accordionExample"
                              >
                                <div className="card-body">
                                  <p>
                                    Hayır. Yönetmelik gereğince ticari
                                    elektronik ileti göndermek isteyen hizmet
                                    sağlayıcıların İleti Yönetim Sistemi’ne
                                    kayıt olması zorunludur.
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="card">
                              <div className="card-header" id="headingSix">
                                <h2 className="mb-0">
                                  <button
                                    className="btn btn-link collapsed"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#collapseSix"
                                    aria-expanded="false"
                                    aria-controls="collapseSix"
                                  >
                                    İleti Yönetim Sistemi'ne kaydettiğim izinler
                                    ne kadar süreyle saklanacak?
                                  </button>
                                </h2>
                              </div>
                              <div
                                id="collapseSix"
                                className="collapse"
                                aria-labelledby="headingSix"
                                data-parent="#accordionExample"
                              >
                                <div className="card-body">
                                  <p>
                                    İleti Yönetim Sistemi'nde tutulan kayıtlar
                                    süresiz olarak saklanır.
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="card">
                              <div className="card-header" id="headingSeven">
                                <h2 className="mb-0">
                                  <button
                                    className="btn btn-link collapsed"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#collapseSeven"
                                    aria-expanded="false"
                                    aria-controls="collapseSeven"
                                  >
                                    Ticari elektronik ileti gönderim onayını
                                    nasıl alabilirim?
                                  </button>
                                </h2>
                              </div>
                              <div
                                id="collapseSeven"
                                className="collapse"
                                aria-labelledby="headingSeven"
                                data-parent="#accordionExample"
                              >
                                <div className="card-body">
                                  <p>
                                    Ticari elektronik ileti gönderim onayı üç
                                    farklı şekilde alınabilir:
                                  </p>

                                  <ul>
                                    <li>Fiziksel ortamda imzayla</li>
                                    <li>Elektronik ortamda</li>
                                    <li>İleti Yönetim Sistemi yoluyla</li>
                                  </ul>
                                </div>
                              </div>
                            </div>

                            <div className="card">
                              <div className="card-header" id="headingEight">
                                <h2 className="mb-0">
                                  <button
                                    className="btn btn-link collapsed"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#collapseEight"
                                    aria-expanded="false"
                                    aria-controls="collapseEight"
                                  >
                                    İleti Yönetim Sistemi'ne yüklediğim izinler hangi sunucularda saklanıyor?
                                  </button>
                                </h2>
                              </div>
                              <div
                                id="collapseEight"
                                className="collapse"
                                aria-labelledby="headingEight"
                                data-parent="#accordionExample"
                              >
                                <div className="card-body">
                                  <p>
                                  İleti Yönetim Sistemi'ne iletilen tüm veriler yurt içindeki güvenlikli veri merkezlerimizde depolanır ve hiçbir şekilde yurt dışına çıkmaz.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </section>
              </div>
            </div>
          </section>
          <Footer />
        </body>
      </Layout>
    )
  }
}
IYSPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}
export default IYSPage
